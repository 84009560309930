import REGEX from './REGEX'
import ERROR_MESSAGES from './ERROR_MESSAGES'
import * as punycode from 'punycode'

export default class Validator {
  constructor(methods) {
    this._validateSet = []
    this.pushMethods(methods)
  }

  pushMethods(methods) {
    this._validateSet.push(...methods)
  }

  validate(value, errorValues = {}) {
    return new Promise((resolve, reject) => {
      const errors = []

      this._validateSet.forEach((validate) => {
        const result = Validator[validate](value, errorValues[validate])
        if (!result.success) {
          errors.push(result.error)
        }
      })

      if (errors.length > 0) {
        reject(errors)
      } else {
        resolve(true)
      }
    })
  }

  static addNewMethod(name, method) {
    Validator[name] = method
  }

  static isEmpty(value) {
    const defaultError = {
      message: ERROR_MESSAGES.IS_EMPTY(),
    }

    return {
      success: REGEX.IS_EMPTY_SET.includes(value),
      error: defaultError,
    }
  }

  static isNotEmpty(value, errorValue) {
    const defaultError = {
      message: ERROR_MESSAGES.IS_NOT_EMPTY(errorValue),
    }

    return {
      success: !REGEX.IS_EMPTY_SET.includes(value),
      error: defaultError,
    }
  }

  static isEmail(value) {
    const defaultError = {
      message: ERROR_MESSAGES.IS_EMAIL(),
    }

    return {
      success: REGEX.IS_EMAIL.test(punycode.toUnicode(value)),
      error: defaultError,
    }
  }

  static isPhone(value) {
    const defaultError = {
      message: ERROR_MESSAGES.IS_PHONE(),
    }

    return {
      success: REGEX.IS_PHONE.test(value),
      error: defaultError,
    }
  }

  static isPassword(value) {
    if (value.length < 6) {
      return {
        success: false,
        error: {
          message: ERROR_MESSAGES.IS_PASSWORD_HAS_ENOUGH_SYMBOLS(),
        },
      }
    }

    if (value.length > 32) {
      return {
        success: false,
        error: {
          message: ERROR_MESSAGES.IS_PASSWORD_HAS_LESS_MAX_SYMBOLS(),
        },
      }
    }

    for (let letters of value.matchAll(REGEX.GET_ALL_LETTERS)) {
      if (!REGEX.IS_LATIN_LETTERS.test(letters[0])) {
        return {
          success: false,
          error: {
            message: ERROR_MESSAGES.IS_PASSWORD_HAS_NOT_ENGLISH_LETTERS(),
          },
        }
      }
    }

    if (REGEX.IS_SPACE_SYMBOL.test(value)) {
      return {
        success: false,
        error: {
          message: ERROR_MESSAGES.IS_SPACE_SYMBOL(),
        },
      }
    }

    if (!REGEX.IS_PASSWORD.test(value)) {
      const passwordDefaultIncorrectSymbols = new Set([
        '%',
        '“',
        '’',
        '$',
        '`',
        '”',
      ])
      const passwordIncorrectSymbols = new Set([
        ...passwordDefaultIncorrectSymbols,
        ...value.replaceAll(REGEX.PASSWORD_CORRECT_SYMBOLS, '').split(''),
      ])
      const passwordIncorrectSymbolsString = Array.from(
        passwordIncorrectSymbols
      ).join(' ')
      return {
        success: false,
        error: {
          message: ERROR_MESSAGES.IS_PASSWORD(passwordIncorrectSymbolsString),
        },
      }
    }

    return {
      success: true,
    }
  }
}
