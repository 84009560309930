import XRegExp from 'xregexp'
export default {
  IS_EMPTY_SET: ['', undefined, null, []],
  IS_EMAIL:
    /^(?:[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
  IS_PHONE:
    /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/i,
  HAS_LETTERS: /[a-zA-Zа-яА-ЯёЁ]/i,
  HAS_DIGITS: /[0-9]/i,
  IS_ONLY_LETTERS_AND_DIGITS: /^[A-Za-z0-9]+$/i,
  IS_PASSWORD: /^[a-zA-Z0-9_#&()*+,./:;<=>?@\[\]]+$/i,
  GET_ALL_LETTERS: XRegExp('\\p{Letter}+', 'ig'),
  IS_LATIN_LETTERS: XRegExp('^\\p{Latin}+$'),
  PASSWORD_CORRECT_SYMBOLS: /[a-zA-Z0-9_#&()*+,./:;<=>?@\[\]]+/gi,
  IS_SPACE_SYMBOL: /[ ]+/i,
}
