import i18n from '@/i18n'

const $t = (val, params = {}) => i18n.global.t(val, params)

export default {
  IS_EMPTY: () => $t(`validator.is_empty`),
  IS_NOT_EMPTY: (fieldName = '') =>
    fieldName
      ? $t(`validator.is_not_empty_with_field_name`, { fieldName: fieldName })
      : $t(`validator.is_not_empty`),
  IS_EMAIL: () => $t(`validator.is_email`),
  IS_PHONE: () => $t(`validator.is_phone`),
  IS_PASSWORD: (extraSymbols = '') =>
    $t(`validator.is_password`, { extraSymbols: extraSymbols }),
  IS_PASSWORD_HAS_ENOUGH_SYMBOLS: () =>
    $t(`validator.is_password_has_enough_symbols`),
  IS_PASSWORD_HAS_NOT_ENGLISH_LETTERS: () =>
    $t(`validator.is_password_has_not_english_letters`),
  IS_PASSWORD_HAS_LESS_MAX_SYMBOLS: () =>
    $t(`validator.is_password_less_max_symbols`),
  IS_SPACE_SYMBOL: () => $t(`validator.is_password_has_space`),
}
