<template>
  <div
    class="c-input"
    :class="{
      'c-input--view-box': isBox,
      'c-input--view-inline': isInline,
    }"
    ref="cInput"
  >
    <div
      class="c-input__container"
      :class="{
        'c-input__container--with-label': toShowLabel,
        'c-input__container-error': toShowError,
      }"
    >
      <label class="c-input__label-container">
        <span class="form__label text text-xs">{{ label }}</span>
        <input
          @focus="onInputFocus"
          @blur="onInputBlur"
          v-model="cValue"
          :type="getType"
          :name="getName"
          :class="
            toShowError
              ? 'animation__border-color--error'
              : 'animation__border-color--default'
          "
          :autocomplete="autocomplete"
          class="form__control animation__border-color text text-md"
          ref="input"
        />
      </label>
      <div
        v-if="toShowEye"
        class="icon-eye-container"
        :class="{ 'icon-eye-container--active': toShowPassword }"
        @click.stop="onEyeClick"
      >
        <icon
          :name="$t('web.icon_eyeOpen')"
          class="icon--eye-open purchase__payment-icon"
          width="24"
          height="34"
        >
          <eye-open />
        </icon>
      </div>
      <div
        class="form__control-helper animation__visibility text text-2xs"
        :class="{
          'animation__visibility--visible': toShowError,
          'animation__visibility--invisible': !toShowError,
          bounce: toBounce,
        }"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { INPUT } from '@/CONSTANTS'
import Validator from '@/utils/Validator'
import Icon from '@/components/Icon'
import EyeOpen from '@/assets/image/icons/svg/eyeOpen.svg'

function isElement(obj) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (
      typeof obj === 'object' &&
      obj.nodeType === 1 &&
      typeof obj.style === 'object' &&
      typeof obj.ownerDocument === 'object'
    )
  }
}

export default {
  name: 'Input',

  components: {
    Icon,
    EyeOpen,
  },

  data() {
    return {
      error: '',
      isFocused: false,
      toBounce: false,
      inputValidator: null,
      toShowPassword: false,

      meta_isAutofilled: false,
    }
  },

  props: {
    type: {
      type: String, // value of INPUT.META.TYPE
      default: INPUT.META.TYPE.TEXT,
    },
    view: {
      type: String, // value of INPUT.META.VIEW
      default: INPUT.META.VIEW.STANDARD,
    },
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    validateMethods: {
      type: Array,
      default: () => [],
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
  },

  emits: ['update:modelValue'],

  watch: {
    error(value) {
      if (value) {
        this.toBounce = true
        setTimeout(() => {
          this.toBounce = false
        }, 1000)
      }
    },
    cValue() {
      this.meta_isAutofilled = false
    },
  },

  computed: {
    isBox: {
      get() {
        return this.view === INPUT.META.VIEW.BOX
      },
    },
    isInline: {
      get() {
        return this.view === INPUT.META.VIEW.INLINE
      },
    },
    cValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
    toShowLabel: {
      get() {
        return !!this.cValue || this.isFocused || this.meta_isAutofilled
      },
    },
    toShowError: {
      get() {
        return !!this.error
      },
    },
    inputValue: {
      get() {
        return this.$refs.input.modelValue
      },
    },
    toShowEye: {
      get() {
        return this.type === INPUT.META.TYPE.PASSWORD
      },
    },
    getType: {
      get() {
        let type = this.type
        if (type === INPUT.META.TYPE.PASSWORD && this.toShowPassword) {
          type = INPUT.META.TYPE.TEXT
        }
        return type
      },
    },
    getName: {
      get() {
        return `${this.type}_${Math.ceil(Math.random() * 1000000)}`
      },
    },
  },

  beforeMount() {
    const methods = this.validateMethods.map((method) =>
      Array.isArray(method) ? method[0] : method
    )
    this.inputValidator = new Validator(methods)
  },

  mounted() {
    // это фикс для автокомплита и значения из истории для v-model
    // TODO: Построить компонент без этого костыля
    this.cValue = this.$refs.input.modelValue

    let i = 0
    const interval = setTimeout(() => {
      if (i > 10) {
        clearInterval(interval)
      }
      i++
      if (this.$refs.input && isElement(this.$refs.input)) {
        this.meta_isAutofilled =
          window.getComputedStyle(this.$refs.input, ':-webkit-autofill')
            .animationName === 'onAutoFillStart'
      }
    }, 100)
  },

  methods: {
    getValue() {
      return this.cValue
    },
    setValue(value) {
      this.cValue = value
    },
    showPassword() {
      this.toShowPassword = true
    },
    hidePassword() {
      this.toShowPassword = false
    },
    setError(error) {
      this.error = error
    },
    onInputFocus() {
      this.$emit('focus')
      this.isFocused = true
      this.error = ''
    },
    onInputBlur() {
      this.$emit('blur')
      this.isFocused = false
      this.validate().catch(() => {})
    },
    validate(toShowError = true) {
      return new Promise((resolve, reject) => {
        if (!this.inputValidator) {
          throw new Error()
        }
        const errorValues = this.validateMethods.reduce((acc, method) => {
          if (Array.isArray(method)) {
            acc[method[0]] = method[1]
          }
          return acc
        }, {})
        this.inputValidator
          .validate(this.cValue, errorValues)
          .then(() => {
            this.error = ''
            resolve()
          })
          .catch((errors) => {
            if (toShowError) {
              this.error = errors[0].message
            }
            reject()
          })
      })
    },
    onEyeClick() {
      this.toShowPassword = !this.toShowPassword
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global/variables';

.bounce {
  outline: 0;
  border-color: red;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
.animation {
  &__visibility {
    transition: opacity $transTimeFast $easeDefault;

    &--visible {
      opacity: 1;
    }
    &--invisible {
      opacity: 0;
    }
  }
}
</style>
